import { Component,ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-term',
  templateUrl: './term.component.html',
  styleUrls: [
    './term.component.scss',
    '../../../assets/bootstrap/css/bootstrap.min.css',
    '../../../assets/css/dashboard/common.css',
    '../../../assets/css/dashboard/style.css'
  ],
  encapsulation: ViewEncapsulation.None,
})
export class TermComponent {
  isUserNav: boolean = false;
  ngOnInit(): void {
    this.isUserNav = true;
  }
  
}

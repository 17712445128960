import { Component } from '@angular/core';
import { BaseConfig, NgxScannerQrcodeService, ScannerQRCodeResult } from 'ngx-scanner-qrcode';
import { QrScanService } from '../../services/qr.service';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';
import { bootstrapApplication } from '@angular/platform-browser';

@Component({
  selector: 'app-mob',
  templateUrl: './mob.component.html',
  styleUrls: ['./mob.component.scss'],
  providers: [MessageService]
})

export class MobComponent {
  public qrCodeResult: ScannerQRCodeResult[] = [];

  constructor(private router: Router,
    private qrcode: NgxScannerQrcodeService,
    private messageService: MessageService, private primengConfig: PrimeNGConfig,
    private qrScanService: QrScanService) { }

  public config: BaseConfig = {
    isAuto: false,
    isDraw: false,
    isBeep: true,
    isAlwaysEmit: true,
    text: {
      font: '25px serif', // Hiden { font: '0px', bottom: 40 },
      fillStyle: 'red'
    },
    frame: {
      strokeStyle: 'red'
    },
    medias: {
      audio: false,
      video: {
        width: { ideal: 1280 },
        height: { ideal: 720 },
        facingMode: 'user', // Front and back camera { facingMode: front ? "user" : "environment" }
      }
    }
  };

  public onEvent(action: any, QrResult: any): void {
    console.log(action);
    console.log(QrResult.data);
    if (QrResult.data != null) {
      var user_id = sessionStorage.getItem('currentUser');
      var QRdata = {'data': QrResult.data};
      this.qrScanService.qr_scan(QRdata).subscribe((res: any) => {
        console.log(res);

        if (res.status == 200) {
          action.stop();
          action.medias.video = false;
          this.showSuccess('Your account is linked to the web application');
          this.router.navigateByUrl('term');
        }
      })
    }

  }

  showSuccess(message: any) {
    this.messageService.add({ severity: 'success', summary: 'Success', detail: message });
  }

  public onError(QrResult: any): void {
    //alert(e);
  }

  public handle(action: any, fn: string): void {
    console.log(action);
    action[fn]().subscribe(console.log, console.log);
  }

  public onStop(action: any, fn: string) {
    action[fn]().subscribe(console.log, console.log);
  }

  public onSelects(files: any) {
    this.qrcode.loadFiles(files, Object.assign({ isDraw: true, isBeep: true }, this.config)).subscribe((res: ScannerQRCodeResult[]) => {
      console.log(res);
      this.qrCodeResult = res.filter(f => f.urlQR);
    });
  }
}
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { UserLoginService } from '../services/login.service';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';


@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
    providers: [MessageService]
})
export class LoginComponent implements OnInit {

    Loginform: FormGroup;

    returnUrl: string | undefined = '/term';
    termId: number | undefined;
    hide = false;

    isLogin = false;

    constructor(
        private router: Router,
        private formbuilder: FormBuilder,
        private messageService: MessageService, private primengConfig: PrimeNGConfig,
        private userLoginService: UserLoginService) {
        this.Loginform = new FormGroup({
            'email': new FormControl('', [Validators.required]),
            'password': new FormControl('', [Validators.required]),
        });
    }



    ngOnInit(): void {

        console.log('Method not implemented.');
        this.primengConfig.ripple = true;
    }

    showSuccess(message: any) {
        console.log('raja');
        this.messageService.add({ severity: 'success', summary: 'Success', detail: message });
    }

    showInfo() {
        this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Message Content' });
    }

    showWarn(message: any) {
        this.messageService.add({ severity: 'warn', summary: 'Warn', detail: message });
    }

    showError(message: any) {
        this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
    }

    clear() {
        this.messageService.clear();
    }

    logout() {
        throw new Error('Method not implemented.');
    }

    login() {
        console.log(this.Loginform.value);
        if (this.Loginform.invalid) {
            console.log('username and password required');
            this.showError('username and password required');
            return;
        } this.userLoginService.login(this.Loginform.value).subscribe((res: any) => {
                console.log(JSON.parse(res));
                var jsonResult = JSON.parse(res);

                try {
                    if (jsonResult.status != 200) {
                        this.showError(jsonResult.messages);
                        console.log(jsonResult.messages);
                        sessionStorage.setItem('currentUser', '');
                    } else {
                        sessionStorage.setItem('currentUser', jsonResult.data.token);
                        this.router.navigateByUrl('term');
                        console.log('login Successfully');
                        this.showSuccess('login Successfully');
                    }
                } catch (err) {
                    console.log(err);
                    this.showSuccess('Something went wrong.! Please try again');
                }
            });
    }


}

<app-header></app-header>

<section id="hero-area-1" class="hero-area-1" style="height:auto;padding:0 0;box-shadow: 0px 9px 20px 0px #e2e2e2;position: relative;">

    <div class="container-fluid slider-container slider-text-1 text-center">

        <div class="row slider-wrapper" style="position: relative;">
            <div class="slider-content">
                <p class="slider-text wow fadeInLeft" style="text-shadow: 12px 13px 20px rgb(0 0 0) 0px 2px 73px;"> ஒரு
                    புதிய மொழியைக் கற்றுக்கொள்வதன் மூலம் பல வாய்ப்புகள் மற்றும் <br> உங்கள் வணிகத்தை மேம்படுத்த முடியும்
                </p>
                <p class="slider-text wow fadeInRight" style="text-shadow: 12px 13px 20px rgb(0 0 0) 0px 2px 73px;">
                    Learning a new language can open up many opportunities and improve your business.</p>
                <p class="slider-text-right wow fadeInUp" style="text-shadow: 12px 13px 20px rgb(0 0 0) 0px 2px 73px;">
                    - Veera Natarajan</p>
            </div>

            <div class="col-md-9">
                <img id="slider_img" class="img-fluid" src="assets/images/world_map.gif" style="width: 100%;height: 100%;">

            </div>
            <div class="col-md-3 wow fadeInDown">
                <img class="img-fluid" src="assets/images/slider_image.png" style="width: 100%;">
            </div>
        </div>
        
    </div>
</section>
<!--/ End Hero Area -->


<section id="workspace" class="workspace_section">
    <div class="container">
        <div class="row">
            <div class="col-md-12 workspace_header_text wow fadeInDown">
                <span>VEERA'S</span> WORKSPACE
            </div>
            <div class="curved_line"></div>

            <div class="col-md-12 workspace_header_text mt-5 wow fadeInUp">
                Undoubtely, Our <span>4 Steps</span> Platform will make you Speak...
            </div>
            <hr class="workspace_hr">
        </div>
    </div>
</section>



<div class="workspace_steps" id="step_1">
    <div class="container">
        <div class="row">

            <div class="col-md-4 wow fadeInLeft">
                <div class="">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <img class="image img-fluid" src="assets/images/c1.png" />
                                <div class="overlay_video">
                                    <a href="videos/demo_hd.mp4" class="video video-popup mfp-fade">

                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <h5 class="card-title text-start"><span class="step_num"></span> <strong>T</strong>HEORY CLASS</h5>
                <div class="col-md-12">
                    <p class="card-text">In this session, You will learn the <br>&quot;key words&quot;
                        of the lesson improving <br>your &#39;word power&#39; and
                        taking <br>you to the next level.
                    </p>
                    <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                </div>

            </div>

            <div class="col-md-2">

            </div>
            <div class="col-md-2">

            </div>

            <div class="col-md-4 wow fadeInRight">
                <div class="">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <img class="image img-fluid" src="assets/images/c2.png" />
                                <div class="overlay_video">
                                    <a href="videos/demo_hd.mp4" class="video video-popup mfp-fade">

                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <h5 class="card-title text-start"><strong>P</strong>RACTICAL SESSION </h5>
                <div class="col-md-12">
                    <p class="card-text">In this session, You will be able to <br>answer and create Your own
                        <br>sentences following the theory classes
                    </p>
                    <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                </div>

            </div>


        </div>

        <div class="row">
            <div class="col-md-6 offset-md-3 wow fadeInDown">
                <div style="position:relative;">
                    <div class="border-animated">
                        <span></span><img src="assets/images/7.png" alt="logo" class="logo logo1" src="assets/images/7.png">
                    </div>
                </div>
            </div>
        </div>

        <div class="row">

            <div class="col-md-4 wow fadeInLeft">
                <div class="">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <img class="image img-fluid" src="assets/images/c3.png" />
                                <div class="overlay_video">
                                    <a href="videos/demo_hd.mp4" class="video video-popup mfp-fade">

                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <h5 class="card-title text-start"><span class="step_num"></span> <strong>S</strong>ELF PRACTICE</h5>
                <div class="col-md-12">
                    <p class="card-text">We have created a great platform for <br>you to practice
                        With a self practice <br>session where you will not forget <br>the lessons
                        You learned in the previous <br>sessions by practicing it over and <br>over again
                    </p>
                    <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                </div>
            </div>

            <div class="col-md-2">

            </div>

            <div class="col-md-2">

            </div>

            <div class="col-md-4 wow fadeInRight">
                <div class="">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-md-12">
                                <img class="image img-fluid" src="assets/images/c4.png" />
                                <div class="overlay_video">
                                    <a href="videos/demo_hd.mp4" class="video video-popup mfp-fade">

                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                <h5 class="card-title text-start"><strong>C</strong>ONVERSATION WITH YOUR PARTNER</h5>
                <div class="col-md-12">

                    <p class="card-text">This platform is made exclusively <br>for you to converse
                        With a Co - Learner, <br>getting an opportunity
                        to speak with others to gain confidence and fluency.
                    </p>
                    <!-- <a href="#" class="btn btn-primary">Go somewhere</a> -->
                </div>
            </div>


        </div>

    </div>
</div>

<section id="portfolio" class="portfolio section" style="background: #fff;padding-top: 3em;">
    <div class="container sections">
        <div class="row">
            <div class="col-lg-12 col-12"> &nbsp;</div>
            <div class="col-lg-12 col-12"> &nbsp;</div>
            <div class="col-lg-12 col-12">
                <div class="row ">
                    <div class="col-lg-6 col-12 mission_hindi_box">
                        <div class="blg_caption_bottom mt-1 mb-3">
                            <div class="">
                                <button type="button" name="checkout" class="btn btn_demo text-uppercase px-3 me-2"
                                    style=" background: #ffffff !important;border: 1px solid #000;"><a
                                        href="https://veerasmissionhindi.com/" style="color:#000;">MISSION <span
                                            style="color:#AE9975;">HINDI.COM</span></a></button>

                            </div>
                        </div>

                    </div>
                    <div class="col-lg-6 col-12 mission_eng_box">
                        <div class="blg_caption_bottom mt-1 mb-3">
                            <div class="">
                                <button type="button" name="checkout" class="btn btn_demo text-uppercase px-3 me-2"
                                    style="    background: #ffffff !important;border: 1px solid #000;"><a href="#"
                                        class="video video-popup mfp-fade" style="color:#000;">MISSION <span
                                            style="color:#AE9975;">ENGLISH.COM</span></a></button>

                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>

        <div class="row ">
            <div class="col-lg-12 col-md-12 col-12 mt-4">
                <div class="portfolio-inner ve_volume_box">
                    <div class="row ">
                        <div class="col-lg-12 col-12" style="position:relative;">
                            <div class="blg_grid_box_1" onmouseover="playCourseVideo('blg_grid_box_1')"
                                onmouseout="pauseCourseVideo('blg_grid_box_1')">
                                <div class="row">
                                    <div class="col-lg-7"
                                        style="background: #e1e1e1;font-family: 'Apple Chancery';color: #000000;border-radius: 5px;border: 1px solid #ddd;padding: 14px;margin-left: auto;margin-right: auto;font-size: 17px;font-weight: 600;line-height: 26px;">
                                        <p style="text-align: left;">Invest your time in you.</p>
                                        <p style="text-align: left;">Put your time into your skills &amp; abilities.</p>
                                        <p style="text-align: left;">Spend some time on your family &amp; true friends.
                                        </p>
                                        <p style="text-align: left;">Improving yourself &amp; your Career is the
                                            strength of your life. </p>
                                        <p style="text-align: left;">Don't let entertainers rule you. </p>
                                        <p style="text-align: left;">Your time &amp; thoughts are precious </p>
                                        <br>
                                        <p style="text-align: right;">- Veera Natarajan</p>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12 col-12 mt-4">
                <img style="width: 100%;" src="assets/images/timeline.png">
            </div>
        </div>


    </div>
</section>

<app-footer></app-footer>
import { Component, OnDestroy, OnInit } from '@angular/core';
import { QrScanService } from '../../services/qr.service';
import { environment } from '../../environments/environment';
import { UUID } from 'angular2-uuid';
import { Router, ActivatedRoute } from '@angular/router';
import { MessageService } from 'primeng/api';
import { PrimeNGConfig } from 'primeng/api';

import {
  catchError,
  interval,
  map,
  Observable,
  of,
  retry,
  Subject,
  Subscription,
  switchMap,
  takeUntil,
  timer,
} from 'rxjs';

@Component({
  selector: 'app-web',
  templateUrl: './web.component.html',
  styleUrls: ['./web.component.scss'],
  providers: [MessageService]
})
export class WebComponent implements OnInit, OnDestroy {
  uuidValue: any;
  baseUrl = environment.baseUrl;

  constructor(private qrScanService: QrScanService, private router: Router, private messageService: MessageService, private primengConfig: PrimeNGConfig,) {

    // setInterval(() => this.refresh(), 5000);
  }

  qrData: any;
  subscription: any;
  fetchData$: Observable<any> = of([]);
  generateUUID() {
    this.uuidValue = UUID.UUID();
    this.qrData = this.baseUrl + 'generate-qr?data=' + this.uuidValue;

  }
  generateId(): string {
    return this.uuidValue;

  }
  ngOnInit() {
    this.generateUUID();
    this.subscription = timer(0, 5000).subscribe((res) => {
      var data = { 'data': this.uuidValue };
      this.qrScanService.qr_login(data).subscribe((res: any) => {
        console.log(res);
        
        if (res.data != '') {
          console.log(res);
          this.subscription.unsubscribe();
          sessionStorage.setItem('currentUser', res.data.token);
          console.log('login Successfully');
          this.showSuccess('login Successfully');
          this.router.navigateByUrl('term');
        }
      });

    });
  }

  showSuccess(message: any) {
    console.log('raja');
    this.messageService.add({ severity: 'success', summary: 'Success', detail: message });
  }

  showInfo() {
    this.messageService.add({ severity: 'info', summary: 'Info', detail: 'Message Content' });
  }

  showWarn(message: any) {
    this.messageService.add({ severity: 'warn', summary: 'Warn', detail: message });
  }

  showError(message: any) {
    this.messageService.add({ severity: 'error', summary: 'Error', detail: message });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}

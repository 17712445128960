<footer id="footer" class="footer wow fadeIn"
    style="background: linear-gradient(90deg, #000 0%, #000 50%, #000) !important;">

    <!--/ End Footer Top -->

    <!-- Footer Bottom -->
    <div class="footer-bottom">
        <div class="container-fluid footer_border_bottom">
            <div class="row">
                <div class="col-12">
                    <div class="text-end footer_c_title me-5">
                        <div class="footer_c_title_f"> NJ VEERA'S </div>
                        <div class="footer_c_title_f"> MISSION</div>
                        <div class="footer_c_title_f"> EDUCATION</div>
                        <div class="footer_c_title_f"> PVT
                        </div>
                        <div class="footer_c_title_f"> LTD</div>
                        <div class="footer_c_title_f"> </div>
                    </div>
                    <div class="text-end footer_c_sub_title me-5">
                        <div class="footer_c_title_f">CHIDAMBARAM, </div>
                        <div class="footer_c_title_f">TAMILNADU, </div>
                        <div class="footer_c_title_f">INDIA</div>
                    </div>
                </div>
            </div>
        </div>
        <hr style="color: #fff;margin-top: 6px;height: 2px;">
        <div class="container-fluid footer_border_bottom">
            <div class="row pt-1">
                <div class="col-lg-5">
                    <div class="copyright text-start ms-5">
                        <p> © Veeras All Rights Reserved</p>
                    </div>
                </div>
                <div class="col-lg-3">
                    <div class="text-start">
                        <!-- Social -->
                        <ul class="social padding-0">
                            <li><a href="#"><img src="assets/images/social/Youtube.png"
                                        style="width: 32px;display:inline-block" alt="Twitter"></a></li>
                            <li><a href="#"><img src="assets/images/social/facebook.png"
                                        style="width: 32px;display:inline-block" alt="Facebook"></a></li>
                            <li><a href="#"><img src="assets/images/social/Instagram.png"
                                        style="width: 32px;display:inline-block" alt="Linked In"></a></li>
                            <li><a href="#"><img src="assets/images/social/Twitter.png"
                                        style="width: 32px;display:inline-block" alt="Linked In"></a></li>

                        </ul>
                        <!--/ End Social -->

                    </div>
                </div>
                <div class="col-lg-4">
                    <div class="copyright text-end me-5">
                        <p>Mail us to: <a href="mailto:support@veeraseducation.com">support@veeraseducation.com</a></p>
                    </div>
                </div>
            </div>
        </div>
        <hr style="color: #fff;margin-top: 6px;height: 2px;">
        <br>
    </div>
    <!--/ End Footer Bottom -->
</footer>
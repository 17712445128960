import { Component } from '@angular/core';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  isLogin: boolean = false;
  isUserNav: boolean = false;
  isOpen = false;
  isDropdownOpen = false;

  ngOnInit(): void {
    console.log(sessionStorage.getItem('currentUser'));
    if (sessionStorage.getItem('currentUser')) {
      this.isLogin = true;
    }
    this.isUserNav = false;
  }

  isLoginUser(): boolean {
    return false;
  }

}


import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';

import { UserLoginService } from '../services//login.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(private userLoginService: UserLoginService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        const currentUser = sessionStorage.getItem('currentUser');
        if (currentUser) {
            // eslint-disable-next-line no-param-reassign
            request = request.clone({
                setHeaders: {
                    Authorization: `${currentUser}`
                }
            });
        }

        return next.handle(request);
    }
}

<mat-grid-list cols="1" class="col-col-lg-12 mx-auto logo_bg_top">
	
</mat-grid-list>

<mat-card class="row justify-content-center align-items-center d-flex p-4 m-auto">
	<mat-grid-list cols="1" class="col-col-lg-5 mx-auto">
		<div class="text-center mb-4">
			<img class="img-fluid" style="width: 56%;" src="assets/images/logo.png">
		</div>

		<mat-card-title>
			<img style="display: block;-webkit-user-select: none;margin: auto;background-color: hsl(0, 0%, 90%);transition: background-color 300ms;"
				src={{qrData}}>
		</mat-card-title>
		<mat-list role="list">
			<mat-list-item role="listitem">1. Install Veeras App on your phone from Play Store.</mat-list-item>
			<mat-list-item role="listitem">2. Tap Scanner and Link a Device.</mat-list-item>
			<mat-list-item role="listitem">3. Point your phone to this screen to capture the code.</mat-list-item>
		</mat-list>
	</mat-grid-list>
</mat-card>
<div class="user-header" *ngIf="!isUserNav">
    <mat-toolbar class="user-header-nav">
        <header id="header" class="header col-12">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-4 col-12">
                        <!-- Logo -->
                        <div class="site_logo">
                            <img alt="logo" src="assets/images/logo.png" style="width: 55%;">
                        </div>
                    </div>
                    <div class="col-lg-8 col-12">
                        <div class="topbar-right mt-2">
                            <a mat-raised-button href="login">Login</a>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    </mat-toolbar>
</div>

<div class="user-header" *ngIf="isUserNav">
    <nav class="navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top">

        <a class="navbar-brand mr-1" [routerLink]="['/term']">

            <img class="img-fluid" alt="" src="assets/images/logo.png" style="width: 222px;">
        </a>
        <!-- Navbar Search -->
        <form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search">
            <div class="input-group d-none">
                <input type="text" class="form-control" placeholder="Search for...">
                <div class="input-group-append">
                    <button class="btn btn-light" type="button">
                        <i class="fas fa-search"></i>
                    </button>
                </div>
            </div>
        </form>
        <!-- Navbar -->
        <ul class="navbar-nav ml-auto ml-md-0 osahan-right-navbar">
            <li class="nav-item mx-1 mt-3">
                <a style="display: block;" [routerLink]="['/qr/mob']">
                    <mat-icon #tooltip="matTooltip" matTooltip="Scan the QR Code for web login"
                        matTooltipPosition="right" matTooltipHideDelay="1000" class="nofitication-count"
                        color="primary">qr_code_scanner</mat-icon>
                </a>
            </li>
            <li class="nav-item mx-1 mt-2">
                <a style="padding-top: 2px;display: block;" [routerLink]="['/home']" >
                    <img src="assets/images/go_to_home_1.jpeg" style="width:34px;">
                </a>
            </li>
            <li class="nav-item dropdown no-arrow mx-1">
                <a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown"
                    aria-haspopup="true" aria-expanded="false">
                    <mat-icon class="nofitication-count" matBadge="15"
                        matBadgeColor="warn">notifications_none</mat-icon>
                </a>
                <div class="dropdown-menu dropdown-menu-right notification-item" aria-labelledby="alertsDropdown">

                </div>
            </li>
            <li class="nav-item dropdown no-arrow osahan-right-navbar-user">
                <a class="nav-link dropdown-toggle user-dropdown-link" href="#" id="userDropdown" role="button"
                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    <img alt="Avatar" src="assets/images/user.png">
                    <span class="user_name_txt"></span>
                </a>
                <div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
                    <a class="dropdown-item" href="#"><i class="fas fa-fw fa-user-circle"></i> &nbsp; My Account</a>
                    <a class="dropdown-item" href="#"><i class="fas fa-fw fa-key"></i> &nbsp; Change Password</a>
                    <div class="dropdown-divider"></div>
                    <a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"><i
                            class="fas fa-fw fa-sign-out-alt"></i> &nbsp; Logout</a>
                </div>
            </li>
        </ul>
    </nav>
</div>
import { NgModule } from '@angular/core';
import { RouterModule, Routes, ExtraOptions } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { MainNavComponent } from './global/main-nav/main-nav.component';
import { TermComponent } from './user/term/term.component';
import { ProfileComponent } from './user/profile/profile.component';
import { WebComponent } from './qr/web/web.component';
import { MobComponent } from './qr/mob/mob.component';
import { HomeComponent } from './home/home.component';

import { AuthguardGuard } from './authservice/authguard.guard';

const routes: Routes = [
  { path: '', redirectTo: '/home', pathMatch: 'full' }, // Home component
  { path: 'home', component: HomeComponent },
  {
    path: '',
    component: MainNavComponent, children: [
      { path: 'login', component: LoginComponent },
      { path: 'term', component: TermComponent, canActivate: [AuthguardGuard]  },
      { path: 'profile', component: ProfileComponent, canActivate: [AuthguardGuard] },
      { path: 'qr/web', component: WebComponent },
      { path: 'qr/mob', component: MobComponent, canActivate: [AuthguardGuard] },
    ]
  }
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})

export class AppRoutingModule { }

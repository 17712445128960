<mat-card class="row justify-content-center align-items-center d-flex p-5 m-auto">
	<mat-grid-list cols="1" class="col-lg-5 mx-auto">
		<div class="text-center mb-4">
			<img class="img-fluid" style="width: 100%;" src="assets/images/logo.png">
		</div>

		<mat-card-title>
			<!-- For camera -->
            <ngx-scanner-qrcode #action="scanner" [config]="config" (event)="onEvent(action,$event)" (error)="onError($event)"></ngx-scanner-qrcode>
		</mat-card-title>

        <mat-card-title>
			
            <!-- Loading -->
            <p *ngIf="action.isLoading">⌛ Loading...</p>

            <!-- start -->
            <button mat-raised-button style="background: #001530;color: #fff;border: 1px solid #001530;" class="btn btn-warning btn-lg btn-block text-uppercase" *ngIf="!action.isStart" (click)="handle(action, 'start')">{{action.isStart ? 'Stop' : 'Scan QR'}}</button>
            <button mat-raised-button style="background: #001530;color: #fff;border: 1px solid #001530;" class="btn btn-warning btn-lg btn-block text-uppercase" *ngIf="action.isStart" (click)="handle(action, 'stop')">{{action.isStart ? 'Stop' : 'Scan QR'}}</button>
            
		</mat-card-title>
	</mat-grid-list>
</mat-card>







import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// import { map } from 'rxjs/operators';
import { BehaviorSubject, Observable } from 'rxjs';
import { environment } from '../environments/environment';


@Injectable({
    providedIn: 'root'
})
export class QrScanService {

    baseUrl = environment.baseUrl;

    private currentUserSubject: BehaviorSubject<any>;
    public currentUser: Observable<any>;

    constructor(private http: HttpClient) {
        this.currentUserSubject = new BehaviorSubject<any>(sessionStorage.getItem('currentUser'));
        this.currentUser = this.currentUserSubject.asObservable();
    }

    public get currentUserValue() {
        return this.currentUserSubject.value;
    }

    public currentSetUserValue(user: any) {
        this.currentUserSubject.next(user);
    }

    logout() {
        // remove user from local storage and set current user to null
        sessionStorage.removeItem('currentUser');
        this.currentUserSubject.next(null);
    }

    qr_scan(data: any) {
        return this.http.post(`${this.baseUrl}update_qr`, data);
    }
    qr_login(data: any) {
        return this.http.post(`${this.baseUrl}check_qr`, data);
    }
}

<p-toast></p-toast>

<mat-card class="row justify-content-center align-items-center d-flex p-5 m-auto login_form">
	<mat-grid-list cols="1" >
		<div class="text-center mb-4">
			<img class="img-fluid" style="width: 100%;" src="assets/images/logo.png">
		</div>

		<mat-card-title>
			<h4 class="fw-bold mt-3 text-center">Welcome Back</h4>
		</mat-card-title>
	</mat-grid-list>


	<form [formGroup]="Loginform">
		<mat-card-content>
			<mat-form-field style="width: 100%;" appearance="fill" class="mb-2">
				<mat-label>Email</mat-label>
				<input matInput formControlName="email" required placeholder="Email">
				<mat-icon matSuffix>account_circle</mat-icon>
				<mat-error *ngIf="Loginform.controls['email'].invalid">Email is required</mat-error>
			</mat-form-field>
			<mat-form-field style="width: 100%;" appearance="fill" class="mb-2">
				<mat-label>Password</mat-label>
				<input matInput [type]="hide ? 'text': 'password'" formControlName="password" required
					autocomplete="on">
				<mat-error *ngIf="Loginform.controls['password'].invalid">password is
					required</mat-error>
				<button type="button" class="btn" mat-icon-button matSuffix (click)="hide= !hide"
					[attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
					<mat-icon>{{hide ? 'visibility_off' : 'visibility'}}</mat-icon>
				</button>
			</mat-form-field>
			<mat-checkbox class="custom-control-input">Remember password</mat-checkbox>
		</mat-card-content>
		<mat-card-actions align="end">
			<button mat-raised-button style="background: #001530;color: #fff;border: 1px solid #001530;" class="btn btn-warning btn-lg btn-block text-uppercase" (click)="login()">Sign in</button>
		</mat-card-actions>
		<mat-card-actions>
			<div class="d-flex justify-content-around">
				<a class="forget-password"><b>Forgot password?</b></a>
			</div>
		</mat-card-actions>
	</form>
</mat-card>


<nav class="navbar navbar-expand navbar-light bg-white static-top osahan-nav sticky-top">

	<a class="navbar-brand mr-1" href="http://veeraseducation.com/index.php">

		<img class="img-fluid" alt="" src="assets/images/logo.png" style="width: 222px;">
	</a>
	<!-- Navbar Search -->
	<form class="d-none d-md-inline-block form-inline ml-auto mr-0 mr-md-5 my-2 my-md-0 osahan-navbar-search">
		<div class="input-group d-none">
			<input type="text" class="form-control" placeholder="Search for...">
			<div class="input-group-append">
				<button class="btn btn-light" type="button">
					<i class="fas fa-search"></i>
				</button>
			</div>
		</div>
	</form>
	<!-- Navbar -->
	<ul class="navbar-nav ml-auto ml-md-0 osahan-right-navbar">
		<li class="nav-item mx-1 mt-3">
			<a style="display: block;" href="qr/mob">
				<mat-icon #tooltip="matTooltip"
				matTooltip="Scan the QR Code for web login"
				matTooltipPosition="right"
				matTooltipHideDelay="1000" class="nofitication-count" color="primary">qr_code_scanner</mat-icon>
			</a>
		</li>
		<li class="nav-item mx-1 mt-2">
			<a style="padding-top: 2px;display: block;" href="#">
				<img src="assets/images/go_to_home_1.jpeg" style="width:34px;">
			</a>
		</li>
		<li class="nav-item dropdown no-arrow mx-1">
			<a class="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<mat-icon class="nofitication-count" matBadge="15" matBadgeColor="warn">notifications_none</mat-icon>
			</a>
			<div class="dropdown-menu dropdown-menu-right notification-item" aria-labelledby="alertsDropdown">

			</div>
		</li>
		<li class="nav-item dropdown no-arrow osahan-right-navbar-user">
			<a class="nav-link dropdown-toggle user-dropdown-link" href="#" id="userDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
				<img alt="Avatar" src="assets/images/user.png">
				<span class="user_name_txt"></span>
			</a>
			<div class="dropdown-menu dropdown-menu-right" aria-labelledby="userDropdown">
				<a class="dropdown-item" href="#"><i class="fas fa-fw fa-user-circle"></i> &nbsp; My Account</a>
				<a class="dropdown-item" href="#"><i class="fas fa-fw fa-key"></i> &nbsp; Change Password</a>
				<div class="dropdown-divider"></div>
				<a class="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal"><i class="fas fa-fw fa-sign-out-alt"></i> &nbsp; Logout</a>
			</div>
		</li>
	</ul>
</nav>

<div id="wrapper" class="dashboard">

	<div id="content-wrapper">
		<div class="container">
			<div class="video-block section-padding">
				<div class="row">
					<div class="col-md-12">
						<div class="main-title">
							<div class="btn-group float-right right-action">
								<div class="dropdown-menu dropdown-menu-right">
									<a class="dropdown-item" href="#"><i class="fas fa-fw fa-star"></i> &nbsp; Top
										Rated</a>
									<a class="dropdown-item" href="#"><i class="fas fa-fw fa-signal"></i> &nbsp;
										Viewed</a>
									<a class="dropdown-item" href="#"><i class="fas fa-fw fa-times-circle"></i>
										&nbsp; Close</a>
								</div>
							</div>
							<h6 class="text-center edu_volume_title text-uppercase">PURCHASED TERM -1</h6>
						</div>
					</div>

					<div class="col-lg-12 col-md-12 col-12">
						<div class="individual_volume">

							<div class=" mx-auto">

								<div class="portfolio-inner ve_volume_box">
									<div class="row hindi_volume_row">
										<div class="col-lg-4 col-12" style="position:relative;">
											<div class="theory_demo blg_grid_box_1">
												<div class="row">
													<div class="col-lg-12 col-12">
														<h4 class="class_volume_title mb-3 text-uppercase">Theory
														</h4>
													</div>

													<div class="col-lg-12 col-12">
														<div class="blg_grid_box blg_grid_box_1">
															<div class="blg_grid_thumb">
																<img style="width:100%;height: 100%;"
																	src="assets/images/s3.jpg" class="img-fluid" alt="">

															</div>
														</div>
													</div>
													<br>
													<div class="col-lg-12 col-12">

													</div>
													<div class="col-lg-12 col-12">
														<h4 class="class_volume_sub_title mt-3 text-uppercase">
															<b>Step -1</b>
														</h4>

													</div>

												</div>

											</div>
											<i class="fa fa-plus volume_combo"></i>
										</div>


										<div class="col-lg-4 col-12" style="position:relative;">
											<div class="theory_demo blg_grid_box_2">
												<div class="row">
													<div class="col-lg-12 col-12">
														<h4 class="class_volume_title mb-3 text-uppercase">PRACTICAL
														</h4>
													</div>

													<div class="col-lg-12 col-12">
														<div class="blg_grid_box blg_grid_box_2">
															<div class="blg_grid_thumb">
																<img style="width:100%;height: 100%;"
																	src="assets/images/s1.jpg" class="img-fluid" alt="">

															</div>
														</div>
													</div>

													<div class="col-lg-12 col-12">
														<h4 class="class_volume_sub_title mt-3 text-uppercase">
															<b>Step -2</b>
														</h4>
													</div>

												</div>

											</div>
											<i class="fa fa-plus volume_combo"></i>
										</div>
										<div class="col-lg-4 col-12" style="position:relative;">
											<div class="theory_demo blg_grid_box_3">
												<div class="row">
													<div class="col-lg-12 col-12">
														<h4 class="class_volume_title mb-3 text-uppercase">PRACTICE
														</h4>
													</div>

													<div class="col-lg-12 col-12">
														<div class="blg_grid_thumb">
															<img style="width:100%;height: 100%;"
																src="assets/images/s2.png" class="img-fluid" alt="">

														</div>
													</div>

													<div class="col-lg-12 col-12">
														<h4 class="class_volume_sub_title mt-3 text-uppercase">
															<b>Step -3</b>
														</h4>
													</div>

												</div>

											</div>
										</div>
										<hr style="width:100%">

										<div class="col-lg-4 col-12">

											<div class="blg_caption_bottom mt-1 mb-3 text-center">
												<div class="">
													<button type="button" name="checkout"
														class="btn btn_demo text-uppercase px-3 me-2"
														style="background: #108c7e !important;"><a href="segment.html"
															class="video video-popup" style="color:#fff;">Click
															here</a></button>

												</div>
											</div>
										</div>

										<div class="col-lg-4 col-12">

											<div class="blg_caption_bottom mt-1 mb-3 text-center">
												<div class="">
													<button type="button" name="checkout"
														class="btn btn_demo text-uppercase px-3 me-2"
														style="background: #108c7e !important;"><a
															href="practical.php?id=1&amp;title=PRACTICAL SEGMENT -1"
															class="video video-popup " style="color:#fff;">Click
															here</a></button>

												</div>
											</div>
										</div>

										<div class="col-lg-4 col-12">

											<div class="blg_caption_bottom mt-1 mb-3 text-center">
												<div class="">
													<button type="button" name="checkout"
														class="btn btn_demo text-uppercase px-3 me-2"
														style="background: #108c7e !important;"><a
															href="self_practice.html" class="video video-popup mfp-fade"
															style="color:#fff;">Click here</a></button>

												</div>
											</div>
										</div>

									</div>


								</div>



							</div>
							<hr>
							<div class="container text-center">
								<h6 class="text-center edu_volume_title term_non_purchase text-uppercase">Yet to Buy
								</h6>
							</div>
							<div class="container">
								<div class="row">
									<div class="col-lg-4 col-12 text-center">
										<div class=" grid-col-auto mb-3">
											<label for="individual_plan_1" class="radio-card individual_plan_1">
												<input name="individual_plan" type="checkbox" id="individual_plan_1"
													value="1">
												<div class="card-content-wrapper">
													<span class="check-icon"></span>
													<div class="card-content text-center mt-3">
														<h2 class=""><span class="word_big">T</span>ERM-2</h2>

													</div>
													<h4 class="px-3 text-uppercase text-center mt-2" data-toggle="modal"
														data-target="#practicalModal"
														style="color: #f28b00;letter-spacing: 8px;font-weight: 700;">
														<i class="fas fa-video" aria-hidden="true"></i>
														<span class="word_big">I</span>NDEX
													</h4>
													<div class="add_to_cart"><img src="assets/images/clk_btn.png">
													</div>

												</div>


											</label>
										</div>
									</div>

									<div class="col-lg-4 col-12 text-center">
										<div class=" grid-col-auto mb-3">
											<label for="individual_plan_2" class="radio-card individual_plan_2">
												<input name="individual_plan" type="checkbox" id="individual_plan_2"
													value="5">
												<div class="card-content-wrapper">
													<span class="check-icon"></span>
													<div class="card-content text-center mt-3">
														<h2 class=""><span class="word_big">T</span>ERM-3</h2>

													</div>
													<h4 class="px-3 text-uppercase text-center mt-2" data-toggle="modal"
														data-target="#practicalModal"
														style="color: #f28b00;letter-spacing: 8px;font-weight: 700;">
														<i class="fas fa-video" aria-hidden="true"></i>
														<span class="word_big">I</span>NDEX
													</h4>
													<div class="add_to_cart"><img src="assets/images/clk_btn.png">
													</div>
												</div>


											</label>
										</div>
									</div>

									<div class="col-lg-4 col-12 text-center">
										<div class=" grid-col-auto mb-3">
											<label for="individual_plan_3" class="radio-card individual_plan_3">
												<input name="individual_plan" type="checkbox" id="individual_plan_3"
													value="3">
												<div class="card-content-wrapper">
													<span class="check-icon"></span>
													<div class="card-content text-center mt-3">
														<h2 class=""><span class="word_big">T</span>ERM-4</h2>

													</div>
													<h4 class="px-3 text-uppercase text-center mt-2" data-toggle="modal"
														data-target="#practicalModal"
														style="color: #f28b00;letter-spacing: 8px;font-weight: 700;">
														<i class="fas fa-video" aria-hidden="true"></i>
														<span class="word_big">I</span>NDEX
													</h4>
													<div class="add_to_cart"><img src="assets/images/clk_btn.png">
													</div>
												</div>


											</label>
										</div>
									</div>
								</div>
								<div class="row mx-auto">
									<div class="col-lg-2 col-12"> </div>
									<div class="col-lg-4 col-12 text-center">
										<div class=" grid-col-auto mb-3">
											<label for="individual_plan_4" class="radio-card individual_plan_4">
												<input name="individual_plan" type="checkbox" id="individual_plan_4"
													value="5">
												<div class="card-content-wrapper">
													<span class="check-icon"></span>
													<div class="card-content text-center mt-3">
														<h2 class=""><span class="word_big">T</span>ERM-5</h2>

													</div>
													<h4 class="px-3 text-uppercase text-center mt-2"
														data-bs-toggle="modal" data-toggle="modal"
														data-target="#practicalModal"
														style="color: #f28b00;letter-spacing: 8px;font-weight: 700;">
														<i class="fas fa-video" aria-hidden="true"></i>
														<span class="word_big">I</span>NDEX
													</h4>
													<div class="add_to_cart"><img src="assets/images/clk_btn.png">
													</div>
												</div>


											</label>
										</div>
									</div>
									<div class="col-lg-4 col-12 text-center">
										<div class=" grid-col-auto mb-3">
											<label for="individual_plan_5" class="radio-card individual_plan_5">
												<input name="individual_plan" type="checkbox" id="individual_plan_5"
													value="5">
												<div class="card-content-wrapper">
													<span class="check-icon"></span>
													<div class="card-content text-center mt-3">
														<h2 class=""><span class="word_big">T</span>ERM-6</h2>

													</div>
													<h4 class="px-3 text-uppercase text-center mt-2" data-toggle="modal"
														data-target="#practicalModal"
														style="color: #f28b00;letter-spacing: 8px;font-weight: 700;">
														<i class="fas fa-video" aria-hidden="true"></i>
														<span class="word_big">I</span>NDEX
													</h4>
													<div class="add_to_cart"><img src="assets/images/clk_btn.png">
													</div>
												</div>


											</label>
										</div>
									</div>
								</div>
							</div>




						</div>


					</div>



				</div>

			</div>
		</div>

		<div class="container">
			<div class="row">
				<div class="col-12">&nbsp;</div>
				<div class="col-12">&nbsp;</div>
			</div>
		</div>
		<!-- Sticky Footer -->
		<footer class="sticky-footer">
			<div class="container">
				<div class="row no-gutters">
					<div class="col-lg-12 col-sm-12 ">
						<p class="mt-1 mb-0 text-center">© Copyright 2022 <strong class="text-dark">Veeras
								Education</strong>. All Rights Reserved<br>

						</p>
					</div>

				</div>
			</div>
		</footer>
	</div>
	<!-- /.content-wrapper -->
</div>


<!-- /#wrapper -->
<!-- Scroll to Top Button-->
<a class="scroll-to-top" href="#page-top" style="display: inline;">
	<mat-icon>keyboard_arrow_up</mat-icon>
</a>

<div class="modal fade" id="practicalModal" tabindex="-1" role="dialog" aria-labelledby="practicalModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 84% !important;">
		<div class="modal-content">
			<div class="modal-header" style="padding: 0.4rem 1rem !important;">
				<h5 class="modal-title" id="exampleModalLabel">TERM - 2</h5>
				<button class="close" type="button" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body" style="padding: 0;margin: 0;background: #000;">
				<div class="single-video">
					<!-- Video -->
					<div class="about-video">
						<div class="single-video">
							<div class="wrapper">


							</div>
						</div>
					</div>
					<!--/ End Video -->
				</div>
			</div>
		</div>
	</div>
</div>

<div class="modal fade" id="practiceModal" tabindex="-1" role="dialog" aria-labelledby="practiceModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 84% !important;">
		<div class="modal-content">
			<div class="modal-header" style="padding: 0.4rem 1rem !important;">
				<h5 class="modal-title" id="practiceModalLabel">PRACTICE</h5>
				<button class="close" type="button" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body" style="padding: 0;margin: 0;background: #000;">
				<img src="img/qr_practice.png" style="width:100%;">

			</div>
		</div>
	</div>
</div>




<!-- Logout Modal-->
<div class="modal fade" id="logoutModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
	aria-hidden="true">
	<div class="modal-dialog modal-sm modal-dialog-centered" role="document">
		<div class="modal-content">
			<div class="modal-header">
				<h5 class="modal-title" id="exampleModalLabel">Ready to Leave?</h5>
				<button class="close" type="button" data-dismiss="modal" aria-label="Close">
					<span aria-hidden="true">×</span>
				</button>
			</div>
			<div class="modal-body">Select "Logout" below if you are ready to end your current session.</div>
			<div class="modal-footer">
				<button class="btn btn-secondary" type="button" data-dismiss="modal">Cancel</button>
				<button class="btn btn-primary" onclick="logout()">Logout</button>
			</div>
		</div>
	</div>
</div>